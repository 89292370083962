import React, { Component } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import ElementWrapper from "./components/ElementWrapper";

//layouts
import Layout from './pages/layout';

// shared
import LoginPage from './pages/auth/login';
import RegisterUserPage from './pages/auth/register';

// creator pages
import CreatorDashboardPage from './pages/creator/dashboard';
import CreatorEditProfilePage from './pages/creator/editprofile';
import CreatorManageRecipePage from './pages/creator/managerecipe';
import CreatorViewRecipePage from './pages/creator/viewrecipe';
import CreatorRecipeListPage from './pages/creator/recipeslist';
import CreatorAddRecipePage from './pages/creator/addrecipe';
import CreatorMarketplacePage from './pages/creator/marketplace';
import CreatorSponsorshipsPage from './pages/creator/sponsorships';

// brand
import BrandDashboardPage from './pages/brand/dashboard';
import BrandManageRecipePage from './pages/brand/managerecipe';
import BrandViewRecipePage from './pages/brand/viewrecipe';
import BrandAppliancePage from './pages/brand/appliances';
import BrandCreatorsPage from './pages/brand/creators';
import BrandRecipeListPage from './pages/brand/recipeslist';
import BrandMarketplacePage from './pages/brand/marketplace';
import BrandSponsorshipsPage from './pages/brand/sponsorships';

// consumer
import ConsumerViewRecipePage from './pages/consumer/viewrecipe';
import ConsumerRecipesPage from './pages/consumer/recipes';
import NewConsumerRecipesPage from './pages/consumer/consumerRecipe.js';
import ConsumerProfilePage from './pages/consumer/profile'
import ConsumerDevicesPage from './pages/consumer/devices'
import ConsumerAddRecipePage from './pages/consumer/addrecipe';

import NoPagePage from './pages/nopage';

import config from "../src/utils/config.js";

import UserService from './services/user.js';
import TenantService from './services/tenant.js';

// css
import './css/shared.css';
import ConsumerpersonalizedRecipeCard from './components/ConsumerpersonalizedRecipeCard.js';


import { Player } from '@lottiefiles/react-lottie-player';
import loader from './loader/main_loading_animation.json'
import ReactGA from "react-ga4";
import CreatorRecipePage from './pages/consumer/CreatorRecipes.js';
import SearchIngredients from './pages/consumer/searchIngredients.js';
import ChrisRecipePage from './pages/consumer/ChrisRecipes.js';
import ChrisRecipePageLogged from './pages/consumer/ChrisRecipeLogged.js';
import GlobalClickTracker from './components/GlobalClickTracker.js';
import RouteChangeTracker from './components/RouteTracker.js';
import PrivacyPolicy from './pages/consumer/PrivacyPolicy.js';
ReactGA.initialize("G-BCXS8HC0H8");

class App extends Component {

  constructor(props) {

    super(props);

    //

    // disable logging on producion
    if (config.NODE_ENV === 'production') {
      console.log = () => { }
      console.error = () => { }
      console.debug = () => { }
    }

    console.log('index > ctor');

    const userToken = localStorage.getItem('userToken');
    const userType = localStorage.getItem('userType');
    const currentCreatorId = localStorage.getItem('currentCreatorId') || config.DEFAULT_CREATOR_ID;

    if (currentCreatorId) localStorage.setItem('currentCreatorId', currentCreatorId);

    this.state = {
      userToken: userToken,
      userType: userType,
      currentCreatorId,
      deferredPrompt: null,
      showInstallButton: false,
    };

  }

  //

  async componentDidMount() {


    console.log({ msg: 'componentDidMount', state: this.state });

    let { userToken, user, tenant } = this.state;

    // if no token, user must login again
    if (!userToken) return;

    // if we have a token, fetch user & tenant objects
    try {
      if (!user) user = await UserService.getCurrentUser(userToken);
      if (!tenant) tenant = await TenantService.getCurrentTenant(userToken);
    } catch (err) {
      console.log({ msg: 'componentDidMount', err });
    }

    this.setState({ user, tenant });

    window.addEventListener('beforeinstallprompt', this.handleBeforeInstallPrompt);
    window.addEventListener('appinstalled', this.handleAppInstalled);
  }

  componentWillUnmount() {
    window.removeEventListener('beforeinstallprompt', this.handleBeforeInstallPrompt);
    window.removeEventListener('appinstalled', this.handleAppInstalled);
  }

  handleBeforeInstallPrompt = (e) => {
    e.preventDefault();
    this.setState({ deferredPrompt: e, showInstallButton: true });
  };

  handleAppInstalled = () => {
    this.setState({ deferredPrompt: null, showInstallButton: false });
  };

  handleInstallClick = () => {
    const { deferredPrompt } = this.state;
    if (deferredPrompt) {
      deferredPrompt.prompt();
      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          console.log('User accepted the install prompt');
        } else {
          console.log('User dismissed the install prompt');
        }
        this.setState({ deferredPrompt: null, showInstallButton: false });
      });
    }
  };

  setLoginState = async ({ userType, userToken }) => {

    console.log({ msg: 'setLoginState', userType, userToken });
    localStorage.setItem('userToken', userToken);

    localStorage.setItem('userType', userType);

    // temporary
    let currentCreatorId = localStorage.getItem('currentCreatorId') || config.DEFAULT_CREATOR_ID;

    let user, tenant;
    try {
      if (!user) user = await UserService.getCurrentUser(userToken);
      if (!tenant) tenant = await TenantService.getCurrentTenant(userToken);
    } catch (err) {
      console.log({ msg: 'setLoginState', err });
      localStorage.clear();
    }
    localStorage.setItem('user_id', user._id);

    this.setState({
      user,
      tenant,
      userToken,
      userType,
      currentCreatorId
    });

  }

  setUserType = (userType) => {
    localStorage.setItem('userType', userType);
    this.setState({ userType });
  }

  logout = () => {
    console.log({ msg: 'logout' });
    this.setState({
      user: undefined,
      userToken: undefined,
      userType: undefined,
      tenant: undefined,
      currentCreatorId: undefined,
    });
  }

  //

  render() {

    let { userType, userToken, user, tenant, currentCreatorId } = this.state;
    console.log({ msg: 'render', 'state': this.state, userToken, userType, user, tenant });
    if (!userToken) return this.renderLoggedOut();
    if (!user || !tenant) {
      return (
        <div className="w-full justify-center flex h-[100vh] items-center">
          <Player
            src={loader}
            className="player w-[40%]"
            loop
            autoplay
          />
        </div>
      )
      //  <div className="loading-icon">
      //   <img src='/loading.svg' />
      // </div>
    }

    return this.renderLoggedIn({ userType, user, tenant, currentCreatorId });

  }

  renderLoggedOut() {

    console.log({ msg: 'renderLoggedOut' });

    localStorage.removeItem('userToken');
    localStorage.removeItem('userType');
    localStorage.removeItem('currentCreatorId');

    return (
      <BrowserRouter>
        <GlobalClickTracker userId={localStorage.getItem('user_id')? localStorage.getItem('user_id') : "Non logged in user"} />
        <RouteChangeTracker/>

        <Routes>
          <Route path="/register" element={<ElementWrapper routeElement={RegisterUserPage} setLoginStateFunc={this.setLoginState} />} />
          <Route path="/creator/chris-de-la-rosa" element={<ElementWrapper routeElement={ChrisRecipePage} />} />
          <Route path="/privacy-policy" element={<ElementWrapper routeElement={PrivacyPolicy} />} />

          <Route path="*" element={<ElementWrapper routeElement={LoginPage} setLoginStateFunc={this.setLoginState} />} />
        </Routes>
      </BrowserRouter>
    );

  }

  renderLoggedIn({ userType, user, tenant, currentCreatorId }) {
    localStorage.setItem('user_id', user._id)
    console.log({ msg: 'renderLoggedIn', userType, user, tenant, currentCreatorId });

    let redirectPage;
    const redirectPages = {
      creator: '/creator/dashboard',
      brand: '/brand/appliances',
      consumer: '/consumer/recipes',
      default: '/',
    };

    if (redirectPages[userType]) {
      redirectPage = (<Navigate to={redirectPages[userType]} />)
    } else {
      redirectPage = (<Navigate to={redirectPages['default']} />)
    }

    console.log('renderLoggedIn > redirectPage: ' + redirectPages[userType]);

    const creatorRoutes = (
      <Route path="/" element={<ElementWrapper setUserTypeFunc={this.setUserType} logoutFunc={this.logout} userType={userType} user={user} tenant={tenant} currentCreatorId={currentCreatorId} routeElement={Layout} />}>
        <Route path="/creator" element={<CreatorDashboardPage user={user} tenant={tenant} />} />
        <Route path="/creator/dashboard" element={<CreatorDashboardPage user={user} tenant={tenant} />} />
        <Route path="/creator/profile/edit" element={<CreatorEditProfilePage user={user} tenant={tenant} />} />
        <Route path="/creator/recipes" element={<CreatorRecipeListPage user={user} tenant={tenant} />} />
        <Route path="/creator/recipes/:recipe_id/manage" element={<ElementWrapper routeElement={CreatorManageRecipePage} user={user} tenant={tenant} />} />
        <Route path="/creator/recipes/:recipe_id/view" element={<ElementWrapper routeElement={CreatorViewRecipePage} user={user} tenant={tenant} />} />
        <Route path="/creator/recipes/create" element={<CreatorAddRecipePage user={user} tenant={tenant} />} />
        <Route path="/creator/marketplace" element={<CreatorMarketplacePage user={user} tenant={tenant} />} />
        <Route path="/creator/sponsorships" element={<CreatorSponsorshipsPage user={user} tenant={tenant} />} />
        <Route path="*" element={<ElementWrapper routeElement={NoPagePage} user={user} tenant={tenant} />} />
      </Route>
    );

    const brandRoutes = (
      <Route path="/" element={<ElementWrapper setUserTypeFunc={this.setUserType} logoutFunc={this.logout} userType={userType} user={user} tenant={tenant} currentCreatorId={currentCreatorId} routeElement={Layout} />}>
        <Route path="/brand" element={<BrandDashboardPage user={user} tenant={tenant} />} />
        <Route path="/brand/dashboard" element={<BrandDashboardPage user={user} tenant={tenant} />} />
        <Route path="/brand/recipes" element={<BrandRecipeListPage user={user} tenant={tenant} />} />
        <Route path="/brand/recipes/:recipe_id/manage" element={<ElementWrapper routeElement={BrandManageRecipePage} user={user} tenant={tenant} />} />
        <Route path="/brand/recipes/:recipe_id/view" element={<ElementWrapper routeElement={BrandViewRecipePage} user={user} tenant={tenant} />} />
        <Route path="/brand/appliances" element={<BrandAppliancePage user={user} tenant={tenant} />} />
        <Route path="/brand/creators" element={<BrandCreatorsPage user={user} tenant={tenant} />} />
        <Route path="/brand/marketplace" element={<BrandMarketplacePage user={user} tenant={tenant} />} />
        <Route path="/brand/sponsorships" element={<BrandSponsorshipsPage user={user} tenant={tenant} />} />
        <Route path="*" element={<ElementWrapper routeElement={NoPagePage} user={user} tenant={tenant} />} />
      </Route>
    );

    const consumerRoutes = (
      <Route path="/" element={<ElementWrapper setUserTypeFunc={this.setUserType} logoutFunc={this.logout} userType={userType} user={user} tenant={tenant} currentCreatorId={currentCreatorId} routeElement={Layout} />}>
        <Route path="/clear/storage" element={
          <div>
            <button style={{
              color: 'white',
              backgroundColor: 'red',
              border: 'none',
              padding: '10px',
              borderRadius: '5px',
              cursor: 'pointer',
              fontSize: '16px',
              fontWeight: 'bold',
              margin: '10px',
              marginTop: '20px',
              marginBottom: '20px',
            }} onClick={() => {
              let keys = Object.keys(localStorage);
              let recipeKeys = keys.filter(key => key.startsWith('recipe_['));
              recipeKeys.forEach(key => {
                localStorage.removeItem(key);
              });
              window.location.href = '/';
            }}>Clear Local Storage</button>
          </div>
        } />
        <Route path="/consumer" element={<ElementWrapper routeElement={NewConsumerRecipesPage} user={user} tenant={tenant} />} key='recipes-index' />
        <Route path="/consumer/recipes" element={<ElementWrapper routeElement={NewConsumerRecipesPage} user={user} tenant={tenant} />} key='recipes' />
        <Route path="/consumer/list/recipes" element={<ElementWrapper routeElement={ConsumerRecipesPage} user={user} tenant={tenant} />} key='recipes' />
        <Route path="/consumer/recipes/categories/:category" element={<ElementWrapper routeElement={ConsumerRecipesPage} user={user} tenant={tenant} />} key='category' />
        <Route path="/consumer/recipes/cuisines/:cuisine" element={<ElementWrapper routeElement={ConsumerRecipesPage} user={user} tenant={tenant} />} key='cuisine' />
        <Route path="/consumer/recipes/diets/:diet" element={<ElementWrapper routeElement={ConsumerRecipesPage} user={user} tenant={tenant} />} key='diet' />
        <Route path="/consumer/recipes/creators/:creator" element={<ElementWrapper routeElement={ChrisRecipePageLogged} user={user} tenant={tenant} />} key='diet' />
        <Route path="/consumer/recipes/whats-in-my-kitchen" element={<ElementWrapper routeElement={SearchIngredients} user={user} tenant={tenant} />} key='diet' />
        <Route path="/consumer/recipes/creator/chris-de-la-rosa" element={<ElementWrapper routeElement={ChrisRecipePageLogged} user={user} tenant={tenant} />} key='diet' />

        <Route path="/consumer/recipes/meals/:meal" element={<ElementWrapper routeElement={ConsumerRecipesPage} user={user} tenant={tenant} />} key='meal' />
        <Route path="/consumer/recipes/ingredients/:ingredient" element={<ElementWrapper routeElement={ConsumerRecipesPage} user={user} tenant={tenant} />} key='ingredient' />
        <Route path="/consumer/recipes/tags/:tag" element={<ElementWrapper routeElement={ConsumerRecipesPage} user={user} tenant={tenant} />} key='tag' />
        <Route path="/consumer/recipes/search/:search" element={<ElementWrapper routeElement={ConsumerRecipesPage} user={user} tenant={tenant} />} key='search' />
        <Route path="/consumer/recipes/:recipe_id/view" element={<ElementWrapper routeElement={ConsumerViewRecipePage} user={user} tenant={tenant} />} />
        <Route path="/consumer/recipes/personalized/view" element={<ElementWrapper routeElement={ConsumerpersonalizedRecipeCard} user={user} tenant={tenant} />} />
        <Route path="/consumer/recipes/create" element={<ConsumerAddRecipePage user={user} tenant={tenant} />} />
        {!user.guest && <Route path="/consumer/profile/edit" element={<ConsumerProfilePage user={user} tenant={tenant} />} />}
        <Route path="/consumer/devices/manage" element={<ConsumerDevicesPage user={user} tenant={tenant} />} />
        <Route path="*" element={<ElementWrapper routeElement={NoPagePage} user={user} tenant={tenant} />} />
      </Route>
    );

    return (

      <BrowserRouter>
         <GlobalClickTracker userId={localStorage.getItem('user_id')? localStorage.getItem('user_id') : "Non logged in user"} />
         <RouteChangeTracker/>
        <Routes>

          <Route index element={redirectPage} />

          <Route path="/register">
            <Route path="/register" element={<ElementWrapper routeElement={RegisterUserPage} />} />
          </Route>

          {userType === "consumer" ? consumerRoutes : <></>}
          {userType === "brand" ? brandRoutes : <></>}
          {userType === "creator" ? creatorRoutes : <></>}

        </Routes>
        {this.state.showInstallButton && (
          <button className="install-button" onClick={this.handleInstallClick}>
            Install App
          </button>
        )}
      </BrowserRouter>
    );

  }

}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  //https://stackoverflow.com/questions/48846289/why-is-my-react-component-is-rendering-twice
  // <React.StrictMode>
  <App />
  // </React.StrictMode>
);

export default App;