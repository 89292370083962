import React, { useState, useRef, useEffect } from 'react';

function MultiSelectDropdown({ id, listOptions, listSelection, listName, personalizeRequestFunc, enabledOptions = [] }) {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOptions, setSelectedOptions] = useState(listSelection || []);
    const dropdownRef = useRef(null); // Reference for the dropdown container

    const toggleDropdown = () => setIsOpen(!isOpen);

    const handleCheckboxChange = (option) => {
        console.log('Option clicked:', option);
        if (!enabledOptions.includes(option.id)) return; // Ignore clicks on disabled options

        const updatedSelection = selectedOptions.includes(option)
            ? selectedOptions.filter(item => item !== option)
            : [...selectedOptions, option];
        setSelectedOptions(updatedSelection);
        personalizeRequestFunc({ [id]: updatedSelection });
    };

    const applySelection = () => {
        setIsOpen(false);
        personalizeRequestFunc({ [id]: selectedOptions });
    };

    const resetSelection = () => {
        setSelectedOptions([]);
        personalizeRequestFunc({ [id]: [] }); // Clear selection in parent
    };

    // Effect to handle clicks outside the dropdown
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        // Bind the event listener
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div className="relative" ref={dropdownRef}>
            {/* Trigger Button */}
            <button
                className="flex items-center justify-center bg-[#0e4621] text-white font-semibold px-4 py-2 rounded-lg shadow newp-button focus:outline-none"
                onClick={toggleDropdown}
            >
                {listName}
            </button>

            {/* Dropdown Content */}
            {isOpen && (
                <div className="absolute bg-white shadow-lg rounded-lg w-64 p-4 z-10 mt-2">
                    {/* Options List */}
                    <ul className="max-h-48 overflow-y-auto">
                        {listOptions.map((option) => (
                            <li key={option.id} className="flex items-center mb-2">
                                <input
                                    type="checkbox"
                                    id={`checkbox-${option.id}`}
                                    checked={selectedOptions.includes(option)}
                                    onChange={() => handleCheckboxChange(option)}
                                    disabled={!enabledOptions.includes(option.id)} // Disable options not in enabledOptions
                                    className={`mr-2 ${!enabledOptions.includes(option.id) ? 'opacity-50 cursor-not-allowed' : ''}`}
                                />
                                <label
                                    htmlFor={`checkbox-${option.id}`}
                                    className={`cursor-pointer text-gray-700 ${!enabledOptions.includes(option.id) ? 'opacity-50' : ''}`}
                                >
                                    {option.label}
                                </label>
                            </li>
                        ))}
                    </ul>

                    {/* Selected Items Display */}
                    {selectedOptions.length > 0 && (
                        <div className="mt-4 p-2 bg-gray-100 rounded text-sm text-gray-700">
                            <p>Selected:</p>
                            <ul className="list-disc pl-5">
                                {selectedOptions.map(option => (
                                    <li key={option.id}>{option.label}</li>
                                ))}
                            </ul>
                        </div>
                    )}

                    {/* Action Buttons */}
                    <div className="flex gap-2 mt-3">
                        <button
                            className="flex-1 bg-gray-300 text-gray-700 font-semibold px-4 py-2 rounded-lg hover:bg-gray-400 focus:outline-none"
                            onClick={resetSelection}
                        >
                            Reset
                        </button>
                        <button
                            className="flex-1 bg-green-500 text-white font-semibold px-4 py-2 rounded-lg hover:bg-green-600 focus:outline-none"
                            onClick={applySelection}
                        >
                            Apply
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
}

export default MultiSelectDropdown;
